//------ Bootstrap
//----- Color system
// scss-docs-start color-variables
$white: #fff;

//----- Buttons + Forms
$btn-padding-x: 1.5rem;
$btn-padding-y: .75rem;
//$btn-border-radius: 0;

//----- Navs
// $nav-link-font-size: null;
// $nav-link-color: $body-color;
// $nav-link-hover-color: $primary;
$navbar-item-margin-x: .75rem;

//------ Wp Alpha
//----- Tools
// $teases: ("grid", "list");

//----- Header
$header-bg: $white;

// == Featured image
$home-featured-image-height: calc(100vh - var(--adminbar-height));
$featured-image-overlay-opacity: .15;
//$featured-image-overlay-color: $secondary;

// === Surtitle
$alpha-surtitle-font-size: 1.15rem;
$alpha-surtitle-text-transform: initial;
$alpha-surtitle-font-family: "Rock Salt", sans-serif;
//$alpha-surtitle-color: #1A1A1A;

// == Parallax overlay
$asp-overlay-bg-opacity: .1;
$asp-overlay-bg-color: #000;

// ----- Contrast
$min-contrast-ratio: 1.5;

// ----- Z-index
$alpha-z-indexes: (1, 2);

//------ Shapes
$shapes: ("square", "circle");

// === Responsive medias with fixed ratio
$media-ratios: ((1 1), (4 3), (3 2), (16 9), (21 9));

//------ Fonts optimization
//----- FontAwesome Icons
// here you can add all the icons you need to use inside your theme
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";
$custom-used-fa-icons: (
  map-pin: $fa-var-map-pin,
  square-pen: $fa-var-square-pen,
  palette: $fa-var-palette,
  people-arrows: $fa-var-people-arrows,
  clouds-moon: $fa-var-clouds-moon,
  family: $fa-var-family,
);
