.media-text-1::before {
  position: absolute;
  top: -25%;
  right: 5%;
  z-index: -1;
  width: 65%;
  height: 100%;
  content: "";
  background: url("/assets/images/filigranes/silhouette-assise.png") no-repeat;
  background-size: contain;
  opacity: .15;
  @include media-breakpoint-up($desktop-breakpoint) {
    width: 25%;
  }
}

.gallery-block-heading::before {
  position: absolute;
  top: -65%;
  right: 5%;
  z-index: -1;
  width: 65%;
  height: 100%;
  content: "";
  background: url("/assets/images/filigranes/portrait-profil.png") no-repeat;
  background-size: contain;
  opacity: .15;
  @include media-breakpoint-up($desktop-breakpoint) {
    top: -50%;
    right: -5%;
    width: 35%;
  }
}

.child-block-list::before {
  position: absolute;
  top: 25%;
  right: 5%;
  left: unset;
  z-index: -1;
  width: 28%;
  height: 100%;
  content: "";
  background: url("/assets/images/filigranes/portrait.png") no-repeat;
  background-size: contain;
  opacity: .15;
  @include media-breakpoint-up($desktop-breakpoint) {
    right: unset;
    left: 5%;
    width: 25%;
  }
}

.child-block-list::after {
  position: absolute;
  top: 0;
  left: 5%;
  z-index: -1;
  width: 40%;
  height: 100%;
  content: "";
  background: url("/assets/images/filigranes/perspective.png") no-repeat;
  background-size: contain;
  opacity: .15;
  @include media-breakpoint-up($desktop-breakpoint) {
    right: 29%;
    left: unset;
    width: 25%;
  }
}

.news-block::before {
  position: absolute;
  top: 15%;
  left: 5%;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background: url("/assets/images/filigranes/silhouette.png") no-repeat;
  background-size: contain;
  opacity: .15;

  @include media-breakpoint-up(md) {
    top: 0;
  }

  @include media-breakpoint-up($desktop-breakpoint) {
    right: 0;
    left: unset;
    width: 50%;
  }
}
