@import "config/grid.yaml";
@import "config/templates.yaml";
@import "config/theme.yaml";
@import "config/woocommerce.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "../init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/pages/home";

// === COMPONENTS
@import "../components/lessons-blocks";
@import "../components/watermark";

.parallax-lesson-ado::before {
  --asp-overlay-bg-color-rgb: rgba(0, 0, 0, .3);
}

.faq-block {
  .accordion-header {
    button {
      font-weight: 700;
    }
  }
}

.w-xl-90 {
  @include media-breakpoint-up(xl) {
    width: 90%;
  }
}

//.gallery-img-block {
//  img:before {
//    position: absolute;
//    bottom: 0;
//    left: -50%;
//    transform: translateX(-50%);
//    width: 100%;
//    height: 100%;
//    content: attr(data-legend);
//  }
//}

//.news-block {
//  img, .tease-home-news {
//    max-height: 500px;
//  }
//}

.image-legend {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
