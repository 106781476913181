// Adults
@include media-breakpoint-up($desktop-breakpoint) {
  .overlay-hover-effect {
    overflow: hidden;

    img {
      transition: all .6s ease;
      transform: scale(1) rotate(0deg);
    }

    &:hover {
      img {
        filter: grayscale(1);
        transform: scale(1.05) rotate(1deg);
      }
    }
  }
}


// Teenagers
@include media-breakpoint-up($desktop-breakpoint) {
  .teenagers-lesson-cards:nth-child(1) {
    rotate: 3deg;
  }

  .teenagers-lesson-cards:nth-child(2) {
    rotate: -13deg;
    transform: translateY(10%);
  }

  .teenagers-lesson-cards:nth-child(3) {
    rotate: 8deg;
  }

  .teenagers-lesson-cards:nth-child(4) {
    rotate: -21deg;
    transform: translate(54%, 7%);
  }

  .teenagers-lesson-cards:nth-child(5) {
    rotate: 15deg;
    transform: translate(52%, -21%);
  }

  .polaroid-over-effect {
    overflow: hidden;

    img {
      transition: all .6s ease;
      transform: scale(1) rotate(0deg);
    }

    &:hover {
      img {
        filter: sepia(.5);
        transform: scale(1.05) rotate(1deg);
      }
    }
  }
}

// Child
.bubble-effect {
  img {
    border-radius: 50%;
  }
}

//@include media-breakpoint-up($desktop-breakpoint) {
//  .child-lesson-cards:nth-child(1) {
//    transform: translate(4%, 7%);
//  }
//
//  .child-lesson-cards:nth-child(2) {
//    transform: translate(4%, 7%);
//  }
//
//  .child-lesson-cards:nth-child(3) {
//    transform: translate(4%, 7%);
//  }
//
//  .child-lesson-cards:nth-child(4) {
//    transform: translate(4%, 7%);
//  }
//
//}

.childv2-block {
  max-width: 450px;
  max-height: 450px;
}

.childv2-block:has(.children-lesson-cards:hover) {
  .children-lesson-cards {
    filter: blur(2px);
    transition: filter .6s ease;
  }
}

.children-lesson-cards {
  &:hover {
    filter: none !important;
  }
}

.content-above-child {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.childv2-block {
  --swiper-pagination-bottom: -10px;
}
